import { type FC, useContext } from 'react';

import Header from '@/Header';
import Badge from '@/ui/Badge';
import UserContext from '@/CurrentUserContext';
import FeatureFlagContext from '@/FeatureFlagContext';

import type Role from '@models/Role';

interface Props {
  roles: Role[];
}

const Roles: FC<Props> = ({ roles }) => {
  const featureFlags = useContext(FeatureFlagContext);
  const user = useContext(UserContext);
  const role = user?.userRole;

  if (!featureFlags.useRoleBasedAccessControl || !role) {
    return null;
  }

  if (!role.canCreateRoles && !role.canEditRoles && !role.canDeleteRoles) {
    return null;
  }

  return (
    <>
      <hr />
      <Header
        title="Roles"
        headerButton={
          role.canCreateRoles
            ? { url: '/settings/security/roles/new', color: 'blue', text: 'Create new role' }
            : undefined
        }
      />
      {roles.map(role => (
        <Role key={role.id} role={role} />
      ))}
    </>
  );
};

interface RoleProps {
  role: Role;
}

const Role: FC<RoleProps> = ({ role }) => {
  return (
    <a className="team" href={`/settings/security/roles/${role.id}`}>
      <div className="card mb-2">
        <div className="card-body py-2">
          <div className="d-flex">
            <div className="title flex-grow-1">
              <strong>{role.name}</strong>
              &nbsp;
              {role.builtIn ? <Badge color="blue">Built-in</Badge> : <Badge color="yellow">Custom</Badge>}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Roles;
