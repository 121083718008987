import { type FC, useContext } from 'react';

import Header from '@/Header';
import Flash from '@/Flash';
import asScreen from '@/Screen';
import UserContext from '@/CurrentUserContext';

import type RoleModel from '@models/Role';
import Alert from '@shared/Alert';

interface Props {
  role: RoleModel;
  usersAssigned: boolean;
}

const Role: FC<Props> = ({ role, usersAssigned }) => {
  const user = useContext(UserContext);
  const currentRole = user?.userRole;
  const deletableRole = currentRole?.canDeleteRoles && !role.builtIn;

  return (
    <>
      <Header
        title={`${role.name} / ${role.builtIn ? 'Built-in' : 'Custom'} Role`}
        headerButton={
          deletableRole
            ? {
                url: `/settings/security/roles/${role.id}`,
                method: 'delete',
                color: 'red',
                disabled: usersAssigned,
                text: 'Delete this role',
              }
            : undefined
        }
      />

      <Flash />

      {deletableRole && usersAssigned && (
        <Alert
          icon="circle-info"
          color="yellow"
          className="mb-3 mt-0"
          content="This role has users assigned to it. You need to remove them first before deleting the role."
        />
      )}
    </>
  );
};

export default asScreen(Role);
