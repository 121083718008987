import { useContext } from 'react';
import type { FC } from 'react';

import Header from '@/Header';
import asScreen from '@/Screen';
import AccountContext from '@/AccountContext';
import FontAwesomeIcon from '@/FontAwesomeIcon';

import type { PsaType } from '@models/Integration';

const Invitations: FC = () => {
  const { integrations } = useContext(AccountContext);

  const availableIntegrations = integrations
    .filter(i => isPsa(i.type))
    .filter(i => i.capabilities.includes('canInviteUsers'))
    .sort((a, b) => a.id - b.id);

  return (
    <>
      <Header title="How would you like to invite new users?" />
      <div className="row">
        {availableIntegrations.map(integration => (
          <div className="col-4 mb-3" key={integration.type}>
            <IntegrationBox psaName={integration.name} psaType={integration.type as PsaType} />
          </div>
        ))}
        <div className="col-4">
          <a href="/settings/users/invitations/email" className="team">
            <div className="card">
              <div className="card-body">
                <div className="email-logo">
                  <FontAwesomeIcon icon="envelope-open" />
                  Email
                </div>
                <div className="integration-info">Invite users via Email.</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

function IntegrationBox({ psaName, psaType }: { psaName: string; psaType: PsaType }) {
  const logoType = psaType.replace('_', '-');

  return (
    <a href={`/settings/users/invitations/${psaType}`} className="team">
      <div className="card">
        <div className="card-body">
          <div className={`${logoType}-logo`} />
          <div className="integration-info">Invite users from {psaName}.</div>
        </div>
      </div>
    </a>
  );
}

function isPsa(integration: string): integration is PsaType {
  return ['autotask', 'connect_wise', 'halo_psa', 'office_365', 'service_now'].includes(integration);
}

export default asScreen(Invitations);
