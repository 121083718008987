import posthog from 'posthog-js';

import { Account } from '@models/Account';
import Plan from '@models/Plan';
import User from '@models/User';

declare global {
  interface Window {
    posthog: typeof posthog;
  }
}

type VALID_EVENT_NAMES =
  | 'dhq_opened'
  | 'dhq_closed'
  | 'dhq_flyout_opened'
  | 'dhq_flyout_closed'
  | 'dhq_admin_activated'
  | 'dhq_appointment_cancelled'
  | 'dhq_appointment_reassigned'
  | 'dhq_appointment_rescheduled';

if (process.env.POSTHOG_API_KEY) {
  posthog.init(process.env.POSTHOG_API_KEY, {
    api_host: process.env.POSTHOG_HOST,
    person_profiles: 'identified_only',
    autocapture: true,
    capture_pageview: true,
    capture_pageleave: true,
    capture_dead_clicks: false,
  });

  window.posthog = posthog;
}

export function identifyUser(user: User) {
  if (!process.env.POSTHOG_API_KEY) return;
  if (!user) return;

  // the distinct id format will be account-name-user-id
  posthog.identify(user.analyticsId, {
    'User Status': user.schedulable ? 'active' : 'inactive',
    'User Role': user.role,
  });
}

export function createGroup(account: Account, plan: Plan) {
  if (!process.env.POSTHOG_API_KEY) return;
  if (!account || !plan) return;

  posthog.group('account', account.analyticsId, {
    'Account ID': account.analyticsId,
    'Account Name': account.name,
    'Plan Name': plan.name,
    'PSA Name': account.psaName,
    'Subscription Status': account.status,
  });
}

export function captureEvent(eventName: VALID_EVENT_NAMES, properties = {}) {
  if (!process.env.POSTHOG_API_KEY) return;

  posthog.capture(eventName, properties);
}
