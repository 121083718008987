import type { FC } from 'react';
import { useContext } from 'react';

import type { Unit } from '@/ui/DurationRow';
import DurationRow from '@/ui/DurationRow';
import DurationWithBusinessHoursRow from '@/ui/DurationWithBusinessHoursRow';
import Expander from '@/ui/Expander';
import RadioButtonRow from '@/ui/RadioButtonRow';
import SelectRow from '@/ui/SelectRow';
import TextAreaRow from '@/ui/TextAreaRow';
import NumberInputRow from '@/ui/NumberInputRow';
import PlanContext from '@/PlanContext';
import Switch from '@/ui/Switch';
import AccountContext from '@/AccountContext';
import FeatureContext from '@/FeatureFlagContext';

import Summary from './Summary';
import StatusConfigurationLink from './StatusConfigurationLink';

import type Errors from '@models/Errors';
import type AppointmentTypeModel from '@models/AppointmentType';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import type { PsaType } from '@models/Integration';
import { hasIntegrationCapability } from '@shared/utilities';
import Row from '@ui/Row';
import { ClosedTicketMode } from '@models/AppointmentType';

interface Props {
  url: string;
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  expanded: boolean;
  canExpand: boolean;
  onExpand: (expanded: boolean) => void;
  dirty: boolean;
}

const TIME_UNITS: Unit[] = ['minutes', 'hours', 'days'];

const SchedulingOptionsConfig: FC<Props> = ({ url, appointmentType, errors, expanded, canExpand, dirty, onExpand }) => {
  const { allowsCloseAppointmentsForClosedTickets, allowsRescheduling } = useContext(PlanContext);
  const { useServiceTicketIsClosedTrigger } = useContext(FeatureContext);
  const { integrations } = useContext(AccountContext);

  const canAccessUpdateTicketStatus =
    hasIntegrationCapability(integrations, 'canAccessUpdateTicketStatus') && !appointmentType.workflow;
  const canAccessCancelAppointmentsIfTicketClosed =
    allowsCloseAppointmentsForClosedTickets && useServiceTicketIsClosedTrigger && !appointmentType.workflow;

  const [allowReschedule, setAllowReschedule] = useStateFromProp<boolean>(appointmentType.clientCanReschedule);
  const [closedTicketMode, setClosedTicketMode] = useStateFromProp(appointmentType.closedTicketMode);
  const [cancelAppointmentsIfTicketClosed, setCancelAppointmentsIfTicketClosed] = useStateFromProp(
    appointmentType.cancelAppointmentsIfTicketClosed
  );

  const handleToggleAllowReschedule = (newValue: string) => {
    setAllowReschedule(newValue === 'true' ? true : false);
  };

  return (
    <Expander
      title="Scheduling Options"
      summary={<Summary appointmentType={appointmentType} allowsRescheduling={allowsRescheduling} />}
      url={url}
      method="PATCH"
      icon="equalizer-a"
      hasErrors={schedulingOptionsHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      {allowsRescheduling && (
        <>
          <RadioButtonRow
            name="appointment_type[client_can_reschedule]"
            value={appointmentType.clientCanReschedule.toString()}
            label="Allow Rescheduling"
            helpText="If this setting is selected, clients will be able to cancel and reschedule appointments
            they've made."
            options={[
              { label: 'Allow clients to cancel and reschedule appointments.', value: 'true' },
              { label: "Don't allow clients to cancel and reschedule appointments.", value: 'false' },
            ]}
            onChange={handleToggleAllowReschedule}
          />

          <DurationRow
            disabled={!allowReschedule}
            name="appointment_type[rescheduling_notice_mins]"
            value={appointmentType.reschedulingNoticeMins}
            label="Minimum Notice for Changes"
            helpText="When a value for this field is set, TimeZest will only permit cancellation or rescheduling at least this period of time before the agreed time of the appointment."
            error={errors.reschedulingNoticeMins}
            units={TIME_UNITS}
          />

          <TextAreaRow
            disabled={!allowReschedule}
            name="appointment_type[rescheduling_notice_note]"
            value={
              appointmentType.reschedulingNoticeNote !== null
                ? appointmentType.reschedulingNoticeNote
                : 'Unfortunately, it is too close to the scheduled time for this appointment to make changes to it. Please contact us directly to reschedule or cancel it.'
            }
            label="Custom Note To Client"
            helpText="TimeZest will display this message to the client if they attempt to cancel or reschedule their appointment later than the configured minimum notice for changes."
            error={errors.reschedulingNoticeNote}
          />
        </>
      )}

      <RadioButtonRow
        name="appointment_type[schedule_over_tentative]"
        value={appointmentType.scheduleOverTentative.toString()}
        label="Tentative Appointments"
        helpText="This setting controls whether TimeZest will offer appointment times which conflict with
            existing tentative schedule entries in the user's calendar. Firm appointments are always
            respected."
        options={[
          { label: 'Only offer appointments where there are no existing schedule entries.', value: 'false' },
          { label: 'Allow appointments to be scheduled over existing tentative schedule entries.', value: 'true' },
        ]}
      />
      <hr className="mb-4" />
      <SelectRow
        name="appointment_type[scheduling_increment_mins]"
        value={appointmentType.schedulingIncrementMins.toString()}
        label="Scheduling Increment"
        helpText="The time between appointment slots offered to your clients for appointments of this type."
        options={[
          { name: '15 minutes', value: '15' },
          { name: '30 minutes', value: '30' },
          { name: '60 minutes', value: '60' },
        ]}
      />
      <DurationWithBusinessHoursRow
        nameDuration="appointment_type[minimum_notice_duration]"
        nameUnit="appointment_type[minimum_notice_unit]"
        duration={appointmentType.minimumNoticeDuration}
        unit={appointmentType.minimumNoticeUnit}
        label="Minimum Notice"
        helpText="To ensure clients don't schedule appointments at the last minute, you can require that
            any appointments are at least this amount of time in the future."
        error={errors.minimumNoticeDuration}
      />
      <DurationWithBusinessHoursRow
        nameDuration="appointment_type[scheduling_window_duration]"
        nameUnit="appointment_type[scheduling_window_unit]"
        duration={appointmentType.schedulingWindowDuration}
        unit={appointmentType.schedulingWindowUnit}
        label="Scheduling Window"
        helpText="This is the maximum amount of time in the future where a client will be able to select
            an appointment. TimeZest interprets '0' as meaning no limit to appointments."
        error={errors.schedulingWindowDuration}
      />
      <NumberInputRow
        name="appointment_type[maximum_appointments_per_day]"
        value={appointmentType.maximumAppointmentsPerDay}
        min={0}
        step={1}
        label="Appointments Per Day"
        helpText=" The maximum number of times that this appointment type can be scheduled in a day for each user. TimeZest interprets '0' as meaning no limit."
        error={errors.maximumAppointmentsPerDay}
      />
      <hr className="mb-4" />
      <DurationRow
        name="appointment_type[buffer_before_mins]"
        value={appointmentType.bufferBeforeMins}
        label="Buffer Before"
        helpText="When scheduling these appointments, TimeZest will only offer times where there is at least
            this much time free before the appointment."
        error={errors.bufferBeforeMins}
        units={TIME_UNITS}
        hintText={
          <Switch
            value={appointmentType.hardBufferBefore}
            attr="appointment_type[hard_buffer_before]"
            namespace="hard-buffer-before"
            label="Write this buffer time into the calendar."
            disabled={false}
          />
        }
      />

      <DurationRow
        name="appointment_type[buffer_after_mins]"
        value={appointmentType.bufferAfterMins}
        label="Buffer After"
        helpText="
        When scheduling these appointments, TimeZest will only offer times where there is at least this much time
        free after the appointment."
        error={errors.bufferAfterMins}
        units={TIME_UNITS}
        hintText={
          <Switch
            value={appointmentType.hardBufferAfter}
            attr="appointment_type[hard_buffer_after]"
            namespace="hard-buffer-after"
            label="Write this buffer time into the calendar."
            disabled={false}
          />
        }
      />

      {hasIntegrationCapability(integrations, 'canAccessTicketing') && (
        <>
          <hr className="mb-4" />
          <RadioButtonRow
            name="appointment_type[closed_ticket_mode]"
            value={closedTicketMode}
            label="Closed Ticket Handling"
            helpText="This option controls what TimeZest will do if the ticket is closed when the client schedules."
            options={[
              { label: 'Prevent clients from scheduling appointments if the ticket is closed.', value: 'reject' },
              { label: 'Allow clients to schedule appointments on closed tickets.', value: 'allow' },
            ]}
            onChange={value => setClosedTicketMode(value as ClosedTicketMode)}
          />
          {!appointmentType.workflow && canAccessCancelAppointmentsIfTicketClosed && (
            <Row
              label=""
              helpText="Enabling this option will cancel any upcoming appointments and scheduling requests that have been sent when the service ticket is closed."
              withInput={false}
            >
              {closedTicketMode === 'reject' ? (
                <Switch
                  value={cancelAppointmentsIfTicketClosed}
                  attr="appointment_type[cancel_appointments_if_ticket_closed]"
                  namespace="cancel-appointments-if-ticket-closed"
                  label="Cancel any appointments if the ticket is closed"
                  disabled={false}
                  onChange={setCancelAppointmentsIfTicketClosed}
                />
              ) : (
                <Switch
                  value={false}
                  attr="appointment_type[cancel_appointments_if_ticket_closed]"
                  namespace="cancel-appointments-if-ticket-closed"
                  label="Cancel any appointments if the ticket is closed"
                  disabled={true}
                />
              )}
            </Row>
          )}
        </>
      )}
      {canAccessUpdateTicketStatus && (
        <>
          <hr className="mb-4" />
          <DurationRow
            name="appointment_type[no_response_deadline_mins]"
            value={appointmentType.noResponseDeadlineMins}
            units={TIME_UNITS}
            label="No-Response Time"
            helpText={
              <>
                If this amount of time passes after sending the client a notification, but they haven&apos;t chosen a
                time, TimeZest will move the ticket into a no-response status configured on&nbsp;
                <StatusConfigurationLink integrations={integrations.map(i => i.type) as PsaType[]} />
                &nbsp;page.
              </>
            }
            error={errors.noResponseDeadlineMins}
          />
        </>
      )}
    </Expander>
  );
};

export default SchedulingOptionsConfig;

export function schedulingOptionsHasErrors(errors: Errors): boolean {
  return !!(
    errors.scheduleOverTentative ||
    errors.schedulingIncrementMins ||
    errors.minimumNoticeDuration ||
    errors.bufferBeforeMins ||
    errors.bufferAfterMins ||
    errors.schedulingWindowDuration ||
    errors.reschedulingNoticeMins ||
    errors.reschedulingNoticeNote ||
    errors.maximumAppointmentsPerDay
  );
}
