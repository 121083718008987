import type { FC } from 'react';

import HeaderAppendedButton from './HeaderAppendedButton';
import type { HeaderButtonProps } from './HeaderAppendedButton';

interface Props {
  title: string;
  subtitle?: string;
  headerButton?: HeaderButtonProps;
}

const Header: FC<Props> = ({ title, subtitle, headerButton }) => {
  const hasAppendedButton = !!headerButton;

  return (
    <div className="d-flex mb-3">
      <h4 className="flex-grow-1">
        <span className="fw-bold">{title}</span>
        {subtitle && <span className="text-muted fw-bold">&nbsp; / &nbsp;{subtitle}</span>}
      </h4>
      <div>{hasAppendedButton && <HeaderAppendedButton {...headerButton} />}</div>
    </div>
  );
};

export default Header;
