import type { FC } from 'react';

import PlanChoice from './PlanChoice';

interface Props {
  chargebeePlanId: string;
  pricingModel: string;
  selectedPlan: string;
  handleSelectPlan: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PlansList: FC<Props> = ({ chargebeePlanId, pricingModel, selectedPlan, handleSelectPlan }) => {
  return (
    <div className="d-grid gap-2">
      {pricingModel === 'per_unit' && (
        <>
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="professional-usd-july-2022"
            planName="TimeZest Professional (Monthly)"
            planPricing="$18/activated user/month"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="professional-usd-july-2022-annual"
            planName="TimeZest Professional (Annual)"
            planPricing="$180/activated user/year"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="advanced-usd-july-2022"
            planName="TimeZest Advanced (Monthly)"
            planPricing="$12/activated user/month"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="advanced-usd-july-2022-annual"
            planName="TimeZest Advanced (Annual)"
            planPricing="$120/activated user/year"
            onChange={handleSelectPlan}
          />
        </>
      )}
      {pricingModel === 'flat_fee' && (
        <>
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="professional-usd-jan-2024"
            planName="TimeZest Professional (Monthly)"
            planPricing="$95 per month (includes up to 4 users); $22/additional user/month')"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="professional-usd-jan-2024-annual"
            planName="TimeZest Professional (Annual)"
            planPricing="$950 per year (includes up to 4 users); $220/additional user/year"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="advanced-usd-jan-2024"
            planName="TimeZest Advanced (Monthly)"
            planPricing="$65 per month (includes up to 4 users); $18/additional user/month"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="advanced-usd-jan-2024-annual"
            planName="TimeZest Advanced (Annual)"
            planPricing="$650 per year (includes up to 4 users); $180/additional user/year"
            onChange={handleSelectPlan}
          />
        </>
      )}
      {pricingModel === 'tiered' && (
        <>
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="professional-usd-june-2024"
            planName="TimeZest Professional (Monthly)"
            planPricing="$95 per month (includes up to 4 users); $22/additional user/month')"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="professional-usd-june-2024-annual"
            planName="TimeZest Professional (Annual)"
            planPricing="$950 per year (includes up to 4 users); $220/additional user/year"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="advanced-usd-june-2024"
            planName="TimeZest Advanced (Monthly)"
            planPricing="$65 per month (includes up to 4 users); $18/additional user/month"
            onChange={handleSelectPlan}
          />
          <PlanChoice
            chargebeePlanId={chargebeePlanId}
            selectedPlan={selectedPlan}
            planId="advanced-usd-june-2024-annual"
            planName="TimeZest Advanced (Annual)"
            planPricing="$650 per year (includes up to 4 users); $180/additional user/year"
            onChange={handleSelectPlan}
          />
        </>
      )}
    </div>
  );
};

export default PlansList;
