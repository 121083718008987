import { createContext } from 'react';

import type User from '@models/User';

export enum UserRole {
  Administrator = 'administrator',
  User = 'user',
}

type UserContext = User & { updateCurrentUser: (data: Partial<User>) => void };

export const NULL_USER: UserContext = {
  id: 0,
  externalId: '',
  name: '',
  email: '',
  role: '',
  slug: '',
  photo: null,
  jobTitle: null,
  schedulable: false,
  resourcePageEnabled: false,
  twoFactorEnabled: false,
  analyticsId: 'timezest-user-0',
  updateCurrentUser: () => { },
  userRole: {
    id: 0,
    name: 'Administrator',
    builtIn: true,
    canActivateUsers: true,
    canCancelAppointments: true,
    canConfigureUsers: true,
    canCreateApiKeys: true,
    canCreateAppointmentTypes: true,
    canCreateCustomAttributes: true,
    canCreateEmailTemplates: true,
    canCreateRoles: true,
    canCreateTeams: true,
    canCreateUsers: true,
    canDeleteApiKeys: true,
    canDeleteAppointmentTypes: true,
    canDeleteEmailTemplates: true,
    canDeleteRoles: true,
    canDeleteTeams: true,
    canDeleteUsers: true,
    canDisable2fa: true,
    canDownloadSchedulingRequests: true,
    canEditAccountBilling: true,
    canEditApiKeys: true,
    canEditAppointmentTypes: true,
    canEditBranding: true,
    canEditCustomAttributes: true,
    canEditEmailSettings: true,
    canEditEmailTemplates: true,
    canEditIntegrations: true,
    canEditRoles: true,
    canEditSecurityPermissions: true,
    canEditTeams: true,
    canEditUsers: true,
    canMarkMyCalendarPrivate: true,
    canReassignAppointments: true,
    canRescheduleAppointments: true,
    canViewAccountBilling: true,
    canViewApiKeys: true,
    canViewAppointmentTypes: true,
    canViewBranding: true,
    canViewCalendars: true,
    canViewCustomAttributes: true,
    canViewEmailSettings: true,
    canViewIntegrations: true,
    canViewSecurityPermissions: true,
    canViewTeams: true,
    canViewUsers: true,
  }
};

export default createContext<UserContext>(NULL_USER);
