import { useContext, type FC } from 'react';

import AccountContext from '@/AccountContext';
import Badge from '@/ui/Badge';
import RadioButtonOption from '@/ui/RadioButtonRow/RadioButtonOption';

const PLAN_IDS = ['free-usd-oct-2020-1type', 'free-usd-apr-2020', 'free-usd-oct-2024-10users'];

interface Props {
  chargebeePlanId: string;
  selectedPlan: string;
  handleSelectPlan: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FreePlanChoice: FC<Props> = ({ chargebeePlanId, selectedPlan, handleSelectPlan }) => {
  const { status } = useContext(AccountContext);

  const selected = PLAN_IDS.includes(selectedPlan);
  const currentPlan = PLAN_IDS.includes(chargebeePlanId);

  return (
    <>
      {status === 'trial' ? (
        <RadioButtonOption
          appendedElement={
            <div className="text-muted small flex-grow-1">
              You can sign up to TimeZest Free once your trial has expired. Until then, you can use all of
              TimeZest&apos;s powerful features for free.
            </div>
          }
          label={
            <>
              <strong>TimeZest Free</strong> &mdash; Free
            </>
          }
          checked={false}
          disabled={true}
          name="chargebee_plan_id"
          value="free-plan"
          onChange={() => {}}
        />
      ) : (
        <RadioButtonOption
          checked={selected}
          disabled={!selected}
          name="chargebee_plan_id"
          value="free-usd-oct-2024-10users"
          appendedElement={
            <>
              {selected ? (
                <p className="text-muted small flex-grow-1">
                  Basic scheduling functionality, including branding, but at the best price of all: free.
                </p>
              ) : (
                <p className="text-danger small flex-grow-1">
                  If you're interested in evaluating our Free plan, we'd love to help! Just click the chat icon below to
                  start a conversation with us. You can also find details on our our plans{' '}
                  <a href="https://timezest.com/pricing/" target="_blank" rel="noopener noreferrer">
                    here
                  </a>
                </p>
              )}
            </>
          }
          label={
            <label htmlFor="chargebee_plan_id_free-usd-oct-2024-10users" className="form-check-label">
              <strong>TimeZest Free</strong> — Free
              {currentPlan && (
                <span>
                  &nbsp;
                  <Badge color="green">Your current plan!</Badge>
                </span>
              )}
            </label>
          }
          onChange={handleSelectPlan}
        />
      )}
    </>
  );
};

export default FreePlanChoice;
