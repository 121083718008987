import type { FC } from 'react';

import asScreen from '@/Screen';
import Flash from '@/Flash';
import Header from '@/Header';

import TwoFactorAuthentication from './TwoFactorAuthentication';
import Settings from './Settings';
import Roles from './Roles';

import type Role from '@models/Role';

interface Props {
  errors: Record<string, string>;
  roles: Role[];
  showTicketNamesUrlScheduling: boolean;
  userAvailabilityEditable: boolean;
  useCaching: boolean;
}

const SecurityAndPersmissions: FC<Props> = props => {
  return (
    <>
      <Header title="Security & Permissions" />
      <Flash />
      <TwoFactorAuthentication />

      <hr />

      <Settings {...props} />

      <Roles roles={props.roles} />
    </>
  );
};

export default asScreen(SecurityAndPersmissions);
